import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`DB Hang Cleans 8-8-8-8 \\@60% 1RM`}</p>
    <p>{`Single Leg BLB’s 8-8-8-8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9-15-21 reps each of:`}</p>
    <p>{`KB Goblet Squats (53/35)`}</p>
    <p>{`K2E’s`}</p>
    <p>{`Double Unders x2 (42-30-etc)`}</p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. Want to
learn the exact number of calories your body burns each day? Metabolic
Testing will be offered before and after the seminar as well! We will
need 20 people in order to have this seminar so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend. \\$35/person
for the seminar.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      